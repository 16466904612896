.ticket {
    font-size: 12px;
}

.ticket td,
.ticket th,
.ticket tr,
.ticket table {
    border-top: 1px solid #eeeeee;
    border-collapse: collapse;
}

td.description,
th.description {
    width: 95px;
    max-width: 95px;
}

td.quantity,
th.quantity {
    width: 40px;
    max-width: 40px;
    word-break: break-all;
}

td.price,
th.price {
    width: 40px;
    max-width: 40px;
    word-break: break-all;
}

.ticket .centered {
    text-align: center;
    align-content: center;
}

.ticket {
    width: 225px;
    max-width: 225px;
}

.printi img {
    max-width: inherit;
    width: inherit;
}

@media print {
    .hidden-print,
    .hidden-print * {
        display: none !important;
    }
}