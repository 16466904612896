@import url('https://fonts.googleapis.com/css2?family=Anek+Malayalam:wght@100;200;300;400;500;600;700;800&display=swap');

body{
    font-family: 'Anek Malayalam', sans-serif;
    font-size: 17px;
}

.submit-btn {
    font-family: 'Anek Malayalam', sans-serif;
    background: #f5b40d;
    border: none;
    outline: none;
    border-radius: 8px;
    font-weight: 700;
    font-size: 14px;
    color: #ffffff;
    min-width: 200px;
    min-height: 36px;
    cursor: pointer;
    outline: none;
}

.submit-btn:disabled {
    background: #e7e8e9;
    color: #9fa3a9;
    cursor: not-allowed;
}

.spinner {
    animation-name: spin;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}