body{
    font-size: 16px;
}

hr {
    margin: 1rem 0;
    color: inherit;
    border: 0;
    border-top: 1px solid;
    opacity: 0.25;
}

.cssTicketTicket {
    font-family: 'Anek Malayalam', sans-serif;
    font-size: 16px;
    width: 294.8px;
    max-width: 294.8px;
    height: auto;
}

.cssTicketTicket td,
.cssTicketTicket th,
.cssTicketTicket tr,
.cssTicketTicket table {
    border-collapse: collapse;
}

td.description,
th.description {
    width: 85px;
    max-width: 85px;
}

td.quantity,
th.quantity {
    width: 80px;
    max-width: 80px;
    word-break: break-all;
}

td.price,
th.price {
    width: 70px;
    max-width: 70px;
    word-break: break-all;
}

.cssTicketTicket .centered {
    text-align: center;
    align-content: center;
}

.printi img {
    max-width: inherit;
    width: inherit;
}

@media print {
    .hidden-print,
    .hidden-print * {
        display: none !important;
    }
}